<template>
  <div class="flu">
    <ul class="flu-list" v-if="files.length > 0">
      <li v-for="(file, index) in files" :key="index + '_' + file.original_name" class="flu-list-item">
        <form-group :errors="hasError(index)">
          <a :href="file.url" target="_blank">
            <span>{{ index + 1 }}. {{ file.original_name }}</span>
            <span @click.prevent="removeFile(index)" class="flu-list-item-delete">
            <icon-close size="11" color="#19191C"/>
          </span>
          </a>
        </form-group>
      </li>
      <li v-if="progress">
        <span class="progress">
          <span class="progress-line" :style="{width: progress + '%'}"></span>
        </span>
      </li>
      <li v-if="error" class="my-error">{{ error }}</li>
    </ul>

    <label class="flu-btn" @click="chooseFiles">
      <icon-attach color="#19191C" size=24 class="flu-btn-icon"/>
      <span>Attach file</span>
      <input type="file" ref="file" :accept="acceptExtensionsList" @change.prevent="chooseFiles">
    </label>
  </div>
</template>

<script>
import IconAttach from "@/assets/icons/IconAttach";
import {mapState} from "vuex";
import IconClose from "../assets/icons/IconClose";
import FormGroup from "@/components/FormGroup";

export default {
  name: 'FileListUploader',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    errors: {
      type: [Object],
      default: () => {}
    }
  },
  components: {
    FormGroup,
    IconClose,
    IconAttach
  },
  data () {
    return {
      progress: false,
      internalFiles: [],
      error: false
    }
  },
  computed: {
    ...mapState({
      accept: state => state.init.fileConditions
    }),
    acceptExtensionsList () {
      if (this.accept && this.accept.extensions) {
        return this.accept.extensions.join(', ')
      }

      return ''
    },
    files: {
      get () {
        return this.value
      },
      set () {
        this.$emit('input', this.internalFiles)
      }
    },
  },
  methods: {
    hasError (index) {
      if ( this.errors && !!this.errors[`financial_statement_files[${index}][original_name]`] ) {
        return this.errors[`financial_statement_files[${index}][original_name]`][0]
      }
      return ''
    },

    chooseFiles (event) {
      this.progress = 0

      const file = event.target.files && event.target.files[0]
          ? event.target.files[0]
          : false

      if (file) {
        const data = new FormData()
        data.append('file', event.target.files[0])

        let config = {
          onUploadProgress: (progressEvent) => {
            let val = Math.round( (progressEvent.loaded * 100) / progressEvent.total)
            this.progress = val

            if (val === 100) {
              this.progress = false
            }
          }
        }

        this.$sdk.registration.files.upload(data, config).then(response => {
          const data = response.data

          if (data.error) {
            this.error = data.error

            setTimeout(() => {
              this.error = false
            }, 3000)

            return
          }

          this.files.push(response.data)
          this.$emit('change')
        }).finally(() => {
          this.progress = false
        })
      }

      this.$refs['file'].value = ''
    },
    removeFile (index) {
      this.$delete(this.files, index)
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
  .flu {
    &-list {
      list-style-type: none;
      list-style-position: inside;
      display: grid;
      grid-template-columns: 1fr;
      flex-direction: column;
      gap: 6px;
      font-size: 12px;
      line-height: 14px;

      &-item {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 8px;
        padding-right: 20px;

        &-delete {
          cursor: pointer;
          vertical-align: middle;
          position: absolute;
          right: 0;
          top: 1px;
          z-index: 2;
        }

        a {
          color: #19191C;
          font-family: "Graphik";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 20px;
          display: inline-block;
          position: relative;
          width: 100%;
          cursor: pointer;
        }
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 500;
      cursor: pointer;
      margin-top: 20px;

      &-icon {
        fill: #19191C;
      }

      span {
        font-size: 14px;
      }

      input {
        visibility: hidden;
        left: -99999px;
        top: -9999px;
        position: absolute;
      }
    }
  }

  .progress {
    border: 1px solid #FDE46A;
    width: 100%;
    height: 14px;
    display: inline-block;
    margin-right: 20px;
    max-width: 120px;
    position: relative;

    &-line {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: rgba(253, 228, 106, 0.5);
      transition: all .2s linear;
    }
  }
</style>
