<template>
  <span class="close" :style="styles" />
</template>

<script>
export default {
  name: 'IconClose',
  props: {
    color: {
      type: String,
      default: '#EA4C17'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  },
  computed: {
    styles () {
      return {
        'background-color': this.color,
        width: this.size + 'px',
        height: this.size + 'px',
      }
    }
  }
}
</script>

<style lang="less">
.close {
  display: inline-block;
  width: 12px;
  height: 12px;
  clip-path: polygon(0 42%, 42% 42%, 42% 0, 58% 0, 58% 42%, 100% 42%, 100% 58%, 58% 58%, 58% 100%, 42% 100%, 42% 58%, 0 58%);
  transform: rotate(45deg);
}
</style>
