<template>
  <input type="text" v-model="val" maxlength="19"/>
</template>

<script>
import numeral from 'numeral'

numeral.localeData().delimiters = {
  thousands: ' ',
  decimal: '.'
}

export default {
  name: 'NumberInput',
  props: {
    value: {
      default: ''
    },
  },
  computed: {
    val: {
      get () {
        if (this.validator(this.value)) {
          return this.formatter(this.value).format()
        } else {
          return this.value
        }
      },
      set (val) {
        if (this.validator(val)) {
          this.$emit('input', this.formatter(val).value())
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  methods: {
    validator (value) {
      return /^[0-9 ]+$/.test(value)
    },
    formatter (value) {
      return numeral(value)
    }
  }
}
</script>
