<template>
  <div class="modal">
    <div class="modal-title main-block-title">Financial statement</div>
    <div class="modal-close" @click="$emit('close')">
      <icon-close color="#19191C" />
    </div>
    <div class="modal-content">
      Financial statement is a collection of summary-level reports about an organization's financial results, financial position, and cash flows. Financial statemnet include the income statement, balance sheet, and statement of cash flows.
    </div>
  </div>
</template>

<script>
import IconClose from "@/assets/icons/IconClose";
export default {
  name: 'FinancialStatementModal',
  components: {
    IconClose
  }
}
</script>

<style lang="less" scoped>
.modal {
  position: absolute;
  left: 0;
  top: 0;
  padding: 40px;
  background: rgba(255, 255, 255, .97);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;


  &-title {}
  &-content {
    line-height: 1.65em;
    overflow-y: scroll;
    max-height: calc(100% - 50px);

    &::-webkit-scrollbar {
      display: none;
    }

    p {
      margin: 20px 0 10px;
    }

    ul {
      list-style-type: decimal;
      list-style-position: inside;
      margin-bottom: 30px;
    }

    b {
      font-weight: 500;
    }
  }
  &-close {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
  }
}
</style>
