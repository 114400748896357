<template>
  <div class="main-block">
    <div class="main-block-title">KYC</div>
    <div class="main-block-content">

      <div class="grid grid-kyc">
        <form-group label="Business sector" :value="form.sic_codes" :errors="errors.sic_codes">
            <v-select multiple class="selected__tags form-control"
                      data-test="sic_codes"
                      v-model="form.sic_codes"
                      :filterable="false"
                      @option:selected="validate(['sic_codes'])"
                      :options="sicCodes"
                      @open="onOpen"
                      @close="onClose"
                      @search="fetchOptions"
            >
            <template #selected-option="{ id, label }">
              <span v-if="id !== 'OTHER'" class="marker">{{ id }}</span>
              <span v-else class="marker">{{ label }}</span>
            </template>
            <template #option="{ id, label }">
              <span v-if="id !== 'OTHER'">{{ id }}, {{ label }}</span>
              <span v-else>{{ label }}</span>
            </template>
            <template #list-footer>
              <li v-show="hasNextPage" ref="load" class="loader" />
            </template>
          </v-select>
        </form-group>

        <form-group label="Maximum single payment" :value="form.max_single_payment" :errors="errors.max_single_payment">
          <number-input data-test="max_single_payment" v-model="form.max_single_payment" @change.native="validate(['max_single_payment'])" class="form-control" />
        </form-group>

        <form-group label="Business sector specification" v-if="canSicInput" :value="form.sic_input" :errors="errors.sic_input">
          <input data-test="sic_input" type="text" v-model="form.sic_input" @change="validate(['sic_input'])" class="form-control">
        </form-group>

        <form-group label="Monthly Turnover" class="grid-full-line" :value="form.monthly_turnover" :errors="errors.monthly_turnover">
          <label data-test="monthly_turnover" class="form-control-custom" v-for="item in moneyTurnoverList" @change="validate(['monthly_turnover'])" :key="item.label">
            <input type="radio" v-model="form.monthly_turnover" :value="item.id">
            <span class="form-control">{{ item.label }}</span>
          </label>
        </form-group>

        <form-group label="Number of payments per month" class="grid-full-line" :value="form.payments_count" :errors="errors.payments_count">
          <label data-test="payments_count" class="form-control-custom" v-for="item in paymentsCountList" :key="item.label" @change="validate(['payments_count'])">
            <input type="radio" v-model="form.payments_count" :value="item.id">
            <span class="form-control">{{ item.label }}</span>
          </label>
        </form-group>

        <form-group label="Source of funds" class="grid-full-line" :value="form.founds_sources" :errors="errors.founds_sources">
          <label data-test="founds_sources" class="form-control-custom" v-for="item in foundsSourcesList" @change="validate(['founds_sources'])" :key="item.label">
            <input type="checkbox" v-model="form.founds_sources" :value="item.id">
            <span class="form-control">{{ item.label }}</span>
          </label>
        </form-group>

        <form-group v-show="canShowFundsInput" label="Source of funds input" :value="form.founds_sources_input" :errors="errors.founds_sources_input">
          <input data-test="founds_sources_input" type="text" class="form-control" v-model="form.founds_sources_input" @change="validate(['founds_sources_input'])">
        </form-group>

        <form-group label="Business description" class="grid-full-line" :value="form.business_description" :errors="errors.business_description">
          <textarea data-test="business_description" class="form-control" v-model="form.business_description" @change="validate(['business_description'])" style="max-width: 100%; min-width: 100%;"/>
        </form-group>

        <div class="grid-full-line financial_statement">
          <div class="main-block-title">Financial statement &nbsp; <icon-info color="#19191C" size="15" @click.native="openModal"/></div>

          <form-group label="Please upload financial statements for the latest available period (if applicable)." :value="form.has_financial_statement" :errors="errors.has_financial_statement">
            <label data-test="is_pep" class="form-control-custom" v-for="item in boolAnswers" :key="item.id">
              <input type="radio" v-model="form.has_financial_statement" :value="item.id" @change="validate(['is_pep'])">
              <span class="form-control">{{ item.label }}</span>
            </label>
          </form-group>

          <form-group
              v-if="form.has_financial_statement === false"
              label="Describe the reason (at least 5 characters)"
              :value="form.financial_statement_reason"
              :errors="errors.financial_statement_reason"
          >
            <textarea
                v-model="form.financial_statement_reason"
                @change="validate(['financial_statement_reason'])"
                class="form-control"
            />
          </form-group>

          <form-group
              v-else-if="form.has_financial_statement === true"
              label=""
              class="no-fixed-height"
              :errors="errors.financial_statement_files"
          >
            <file-list-uploader
                v-model="form.financial_statement_files"
                @change="validate(['financial_statement_files'])"
                :errors="errors"
            />
          </form-group>
        </div>

        <div class="grid-full-line form-buttons text-right">
          <button type="submit" class="btn-next" @click="submit" data-test="submit">
            <span>Save and proceed</span>
            <icon-next color="#19191C" size="15"/>
          </button>
        </div>
      </div>

    </div>

    <transition  name="fade">
      <financial-statement-modal v-show="modal" @close="closeModal"/>
    </transition>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import IconNext from "@/assets/icons/IconNext";
import IconInfo from "@/assets/icons/IconInfo";

import { mapGetters, mapState } from 'vuex'
import NumberInput from "@/components/NumberInput";
import FileListUploader from "../../components/FileListUploader";
import FinancialStatementModal from "../modals/FinancialStatementModal";

import debounce from "lodash.debounce";

const OTHER = 'OTHER'

export default {
  name: 'BusinessKYC',
  components: {
    FileListUploader,
    NumberInput,
    FormGroup,
    IconNext,
    IconInfo,
    FinancialStatementModal
  },
  data () {
    return {
      modal: false,

      form: {
        sic_codes: [],
        monthly_turnover: '',
        max_single_payment: '',
        payments_count: '',

        founds_sources: [],
        founds_sources_input: '',

        business_description: '',
        sic_input: '',

        has_financial_statement: null,
        financial_statement_reason: '',
        financial_statement_files: []
      },

      errors: {},

      observer: null,
      sicCodesSearch: ''
    }
  },
  computed: {
    ...mapGetters(['getRegistrationDataPart']),
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      sicCodes: state => state.codes.sicCodes,
      totalPages: state => state.codes.totalPages,
      currentPage: state => state.codes.page,

      businessSector: state => state.init.kycBusinessSectors,
      moneyTurnoverList: state => state.init.kycMoneyTurnover,
      paymentsCountList: state => state.init.kycPaymentsCount,
      foundsSourcesList: state => state.init.kycFoundSources,
      boolAnswers: state => state.init.boolAnswers,
    }),

    canShowFundsInput () {
      return this.form.founds_sources.includes(OTHER)
    },
    canSicInput () {
      return this.form.sic_codes.find(i => i.id === OTHER)
    },

    formData () {
      return {
        sic_codes: this.form.sic_codes.map(i => i.id),
        monthly_turnover: this.form.monthly_turnover,
        max_single_payment: this.form.max_single_payment,
        payments_count: this.form.payments_count,
        founds_sources: this.form.founds_sources,
        founds_sources_input: this.form.founds_sources_input,
        business_description: this.form.business_description,
        sic_input: this.form.sic_input,
        has_financial_statement: this.form.has_financial_statement,
        financial_statement_files: this.formDataFiles,
        financial_statement_reason: this.form.financial_statement_reason
      }
    },

    formDataFiles () {
      const items = []

      for (let file of this.form.financial_statement_files) {
        items.push({
          original_name: file.original_name,
          name: file.name,
          type: 'financial_statement'
        })
      }

      return items
    },

    hasNextPage() {
      return this.currentPage <= this.totalPages
    },

  },
  watch: {
    'form.has_financial_statement' (newVal) {

      if (newVal) {
        this.form.financial_statement_reason = ''
        this.$delete(this.errors, 'financial_statement_reason')
      } else {
        this.form.financial_statement_files = []
        this.$delete(this.errors, 'financial_statement_files')
      }

      this.$delete(this.errors, 'has_financial_statement')
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)

    const data = this.getRegistrationDataPart('kyc').attributes

    if (data) {
      this.form = data
    }

    if (this.sicCodes.length === 0) {
      this.$store.dispatch('getSicCodes', { search: this.sicCodesSearch, page: this.currentPage })
    }
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },

    onClose() {
      this.observer.disconnect()
    },

    async infiniteScroll([{ isIntersecting, target }]) {
      if (this.hasNextPage && isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.$store.dispatch('sicCodes.more', { search: this.sicCodesSearch, page: this.currentPage })
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    submit () {
      this.$sdk.registration.kyc.update(this.registrationHash, this.formData, 0).then((resp) => {
        if (Object.keys(resp.data).length === 0) {
          this.$store.dispatch('updateRegistration').then(() => {
            this.$emit('next-step')
          })
        } else {
          this.errors = resp.data
        }
      })
    },

    validate (attributes = []) {
      for (let name of ['financial_statement_files']) {
        let filesIndex = attributes.indexOf(name)
        if (filesIndex !== -1) {
          for (let i in this.formData[name]) {
            attributes.push(name + '[' + i + '][original_name]')
          }
        }
      }

      this.$sdk.registration.kyc.update(this.registrationHash, this.formData, 1).then((response) => {
        for (const attr of attributes) {
          if (response.data[attr] !== undefined) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      })
    },
    openModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
    },

    fetchOptions(search, loading) {
      loading(true)
      this.search(search, loading)
    },

    search: debounce(async function (search, loading) {
      if (search.length > 0) {
        this.sicCodesSearch = search
        loading(true)
        await this.$store.dispatch('sicCodes.reset')
        await this.$store.dispatch('sicCodes.more', { search: search, page: this.currentPage })
      } else {
        this.sicCodesSearch = ''
        await this.$store.dispatch('sicCodes.reset')
        await this.$store.dispatch('getSicCodes', { search: this.sicCodesSearch, page: this.currentPage })
      }
      loading(false)
    }, 150)
  }
}
</script>

<style lang="less">
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1px;
  }

  .grid-full-line {
    grid-column: auto / span 2;

    @media all and (max-width: 768px) {
      grid-column: auto;
    }
  }

  .financial_statement {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-control-custom,
    .main-block-title {
      margin-bottom: 0;
    }

    .no-fixed-height .control-label {
      min-height: auto;
    }
  }
  .selected__tags {
    .vs__dropdown-toggle {
      padding: 7px 9px 7px 15px;
      min-height: 34px;

      .vs__selected-options {
        align-content: center;
        display: flex;
        gap: 6px;
        height: auto;
        min-height: 21px;

        .vs__selected {
          display: flex;
          flex-direction: row;
          gap: 3px;
          align-items: center;
          justify-items: center;

          border: none;
          color: #fff;
          font-weight: 500;
          background-color: #19191C;
          padding: 4px 8px 3px 8px;

          .vs__icon-indicator-remove {
            &:before,
            &:after {
              background: #fff;
            }
          }

          .marker {
            color: #FFF;
          }
        }
      }
    }

    .vs__dropdown-menu {
      overflow-x: hidden;

      li.vs__dropdown-option--selected {
        background-color: rgba(253, 228, 106, 0.3);

        &:hover {
          background-color: rgba(253, 228, 106, 0.5);
        }
      }
    }
  }

</style>
